<template>
  <v-menu open-on-hover bottom offset-y :close-on-content-click="false" :disabled="isShowAvatarInfo()">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        @click.stop="click"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="{ ...on, ...$listeners }"
        style="cursor: pointer"
      >
        <v-img class="img" style="border: solid 2px #ffffff;" :src="getImageIpfs(hash, 'avatar')" alt="avatar" />
      </v-avatar>
    </template>
    <AvatarInfo :did="did" />
  </v-menu>
</template>

<script>
import AvatarInfo from "./AvatarInfo.vue";
import logoimg from "@/assets/default.png"
import { getFileSrc } from "@/utils/file";
// import api from "@/api";

const AVATAR_KEY = "AVATAR";

export default {
  name: "Avatar",
  inheritAttrs: false,
  components: {
    AvatarInfo,
  },
  props: {
    hash: {
      type: String,
    },
    did: {
      type: String,
    },
    showAvatarInfo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    logoimg,
    src: undefined,
    showAvatar: false,
    avaInfo: {},
	  isMenuBottom: false
  }),
  mounted() {
	  // if(this.showAvatarInfo && !this.did) {
		 //  this.showAvatarInfo = false;
	  // }
    // this.getInfo()
  },
  methods: {
    click() {
      console.log("wxl --- his.did",this.did)
      if(!this.did) return;
      
      if(this.did == this.$store.state.did){
        this.$router.push({
          name: "PersonalPage",
          query: { did: this.did },
        });
      }else{
        this.$router.push({
          name: "Otherpage",
          query: { did: this.did },
        });
      }
    },
	isShowAvatarInfo() {
		if(this.showAvatarInfo && this.did) {
			 return false;
		} else {
			return true;
		}
	}
  },
};
</script>

<style lang="scss" scoped>
// .v-menu__content{
//   position:fixed !important;
//   top: 10px !important;
// }
    .img ::v-deep.v-responsive__sizer {
        // padding-top: 100px !important;
        padding-bottom: 100% !important;
    }
</style>
